export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateOnly: { input: any; output: any };
  DateTime: { input: string; output: string };
};

export interface Analytics {
  __typename?: 'Analytics';
  activeUsers: Scalars['Float']['output'];
  dailyUsers: Array<AnalyticsData>;
  districts: Array<AnalyticsData>;
  loginMethods: Array<AnalyticsData>;
  topBookFilters: Array<AnalyticsData>;
  topBooks: Array<AnalyticsData>;
  topSearches: Array<AnalyticsData>;
}

export interface AnalyticsData {
  __typename?: 'AnalyticsData';
  name: Scalars['String']['output'];
  previousValue?: Maybe<Scalars['Float']['output']>;
  value: Scalars['Float']['output'];
}

export interface AssignedRole {
  __typename?: 'AssignedRole';
  role: Role;
  unitId: Scalars['String']['output'];
}

export interface AssignedRoleInput {
  role: Role;
  unitId: Scalars['String']['input'];
}

export interface Book {
  __typename?: 'Book';
  alternate?: Maybe<Book>;
  asset?: Maybe<Scalars['String']['output']>;
  bigBook?: Maybe<Book>;
  bookType?: Maybe<BookType>;
  curriculumConnection?: Maybe<CurriculumConnection>;
  flashCards?: Maybe<Book>;
  id: Scalars['ID']['output'];
  includedInDemo?: Maybe<Scalars['Boolean']['output']>;
  investigations?: Maybe<Book>;
  language?: Maybe<Language>;
  lessonPlan?: Maybe<Book>;
  pairedText1?: Maybe<Book>;
  pairedText2?: Maybe<Book>;
  perspectives?: Maybe<Book>;
  programOverview?: Maybe<Book>;
  related?: Maybe<Book>;
  runningRecord?: Maybe<Book>;
  series: Series;
  stage?: Maybe<Stage>;
  teacherResourceBook?: Maybe<Book>;
  teachingNote?: Maybe<Book>;
  teksLessonPlan?: Maybe<Book>;
  textType?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  videoAsset?: Maybe<Scalars['String']['output']>;
  vocabulary?: Maybe<Book>;
  vocabularySideB?: Maybe<Book>;
}

export interface BookType {
  __typename?: 'BookType';
  id: Scalars['Int']['output'];
  key: BookTypeEnum;
  value: Scalars['String']['output'];
}

export enum BookTypeEnum {
  BIG_BOOK = 'BIG_BOOK',
  FLASH_CARDS = 'FLASH_CARDS',
  INVESTIGATIONS = 'INVESTIGATIONS',
  LESSON_PLAN = 'LESSON_PLAN',
  PROGRAM_OVERVIEW = 'PROGRAM_OVERVIEW',
  RUNNING_RECORD = 'RUNNING_RECORD',
  STUDENT_BOOK = 'STUDENT_BOOK',
  TEACHER_RESOURCE_BOOK = 'TEACHER_RESOURCE_BOOK',
  TEACHING_NOTES = 'TEACHING_NOTES',
  TEKS_LESSON_PLAN = 'TEKS_LESSON_PLAN',
  VOCABULARY_STARTER = 'VOCABULARY_STARTER',
  VOCABULARY_STARTER_SIDE_B = 'VOCABULARY_STARTER_SIDE_B'
}

export interface BooksFilters {
  bookType?: InputMaybe<Array<Scalars['Int']['input']>>;
  curriculumConnection?: InputMaybe<Array<Scalars['Int']['input']>>;
  id?: InputMaybe<Array<Scalars['ID']['input']>>;
  language?: InputMaybe<Array<Scalars['Int']['input']>>;
  level?: InputMaybe<Array<Scalars['Int']['input']>>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  series?: InputMaybe<Array<Scalars['Int']['input']>>;
  stage?: InputMaybe<Array<Scalars['Int']['input']>>;
}

export interface CreateDistrictInput {
  externalId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
}

export interface CreateSchoolInput {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  districtId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  state: Scalars['String']['input'];
  zip: Scalars['String']['input'];
}

export interface CreateSubscriptionInput {
  from: Scalars['DateTime']['input'];
  items: Array<SubscriptionItemInput>;
  to: Scalars['DateTime']['input'];
  unitId: Scalars['ID']['input'];
}

export interface CreateUserInput {
  assignedRole: AssignedRoleInput;
  classroom?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  gradeLevel?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  loginPath?: InputMaybe<Scalars['String']['input']>;
  schoolBuilding?: InputMaybe<Scalars['String']['input']>;
}

export interface CurriculumConnection {
  __typename?: 'CurriculumConnection';
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
}

export interface District {
  __typename?: 'District';
  active: Scalars['Boolean']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
}

export interface DistrictFilters {
  districtIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
}

export interface EducationalUnit {
  __typename?: 'EducationalUnit';
  id: Scalars['ID']['output'];
}

export interface ExternalId {
  __typename?: 'ExternalId';
  id: Scalars['String']['output'];
  system: ExternalSystem;
}

export enum ExternalSystem {
  CLEVER = 'CLEVER',
  EDLINK = 'EDLINK'
}

export interface Filters {
  __typename?: 'Filters';
  bookTypes: Array<BookType>;
  curriculumConnections: Array<CurriculumConnection>;
  languages: Array<Language>;
  levels: Array<Level>;
  series: Array<Series>;
  stages: Array<Stage>;
}

export interface GetPage {
  skip: Scalars['Int']['input'];
  take: Scalars['Int']['input'];
}

export interface Language {
  __typename?: 'Language';
  id: Scalars['Int']['output'];
  key: LanguageEnum;
  value: Scalars['String']['output'];
}

export enum LanguageEnum {
  ENGLISH = 'ENGLISH',
  SPANISH = 'SPANISH'
}

export interface Level {
  __typename?: 'Level';
  id: Scalars['Int']['output'];
  key: LevelEnum;
  value: Scalars['String']['output'];
}

export enum LevelEnum {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  L = 'L',
  M = 'M',
  N = 'N',
  O = 'O',
  P = 'P',
  PRE_K = 'PRE_K',
  Q = 'Q',
  R = 'R',
  S = 'S',
  T = 'T',
  U = 'U',
  V = 'V'
}

export interface Link {
  __typename?: 'Link';
  book: Book;
  id: Scalars['ID']['output'];
}

export interface Me {
  __typename?: 'Me';
  district?: Maybe<District>;
  school?: Maybe<School>;
  user: User;
}

export interface Mutation {
  __typename?: 'Mutation';
  activateUnit: Scalars['Boolean']['output'];
  activateUsers: Scalars['Boolean']['output'];
  clever: Token;
  createDistrict: District;
  createLink: Link;
  createSchool: School;
  createSubscription: Subscription;
  createUser: User;
  deactivateUnit: Scalars['Boolean']['output'];
  deactivateUsers: Scalars['Boolean']['output'];
  deleteSubscription: Subscription;
  edlink: Token;
  login: Token;
  resendInvitationEmail: Scalars['Boolean']['output'];
  resetPassword: Scalars['Boolean']['output'];
  updateDistrict: District;
  updatePassword: User;
  updateSchool: School;
  updateSubscription: Subscription;
  updateUser: User;
}

export type MutationActivateUnitArgs = {
  unitId: Scalars['String']['input'];
};

export type MutationActivateUsersArgs = {
  userIds: Array<Scalars['ID']['input']>;
};

export type MutationCleverArgs = {
  code: Scalars['String']['input'];
  scope?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateDistrictArgs = {
  district: CreateDistrictInput;
};

export type MutationCreateLinkArgs = {
  bookId: Scalars['ID']['input'];
};

export type MutationCreateSchoolArgs = {
  school: CreateSchoolInput;
};

export type MutationCreateSubscriptionArgs = {
  subscription: CreateSubscriptionInput;
};

export type MutationCreateUserArgs = {
  user: CreateUserInput;
};

export type MutationDeactivateUnitArgs = {
  unitId: Scalars['String']['input'];
};

export type MutationDeactivateUsersArgs = {
  userIds: Array<Scalars['ID']['input']>;
};

export type MutationDeleteSubscriptionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationEdlinkArgs = {
  code: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
};

export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MutationResendInvitationEmailArgs = {
  userId: Scalars['String']['input'];
};

export type MutationResetPasswordArgs = {
  email: Scalars['String']['input'];
};

export type MutationUpdateDistrictArgs = {
  district: UpdateDistrictInput;
};

export type MutationUpdatePasswordArgs = {
  passwordInput: UpdatePasswordInput;
};

export type MutationUpdateSchoolArgs = {
  school: UpdateSchoolInput;
};

export type MutationUpdateSubscriptionArgs = {
  subscription: UpdateSubscriptionInput;
};

export type MutationUpdateUserArgs = {
  user: UpdateUserInput;
};

export interface PaginatedBook {
  __typename?: 'PaginatedBook';
  nodes?: Maybe<Array<Book>>;
  totalCount: Scalars['Int']['output'];
}

export interface PaginatedDistrict {
  __typename?: 'PaginatedDistrict';
  nodes?: Maybe<Array<District>>;
  totalCount: Scalars['Int']['output'];
}

export interface PaginatedSchool {
  __typename?: 'PaginatedSchool';
  nodes?: Maybe<Array<School>>;
  totalCount: Scalars['Int']['output'];
}

export interface PaginatedUser {
  __typename?: 'PaginatedUser';
  nodes?: Maybe<Array<User>>;
  totalCount: Scalars['Int']['output'];
}

export interface Query {
  __typename?: 'Query';
  analytics: Analytics;
  book: Book;
  books: PaginatedBook;
  district: District;
  districts: PaginatedDistrict;
  filters: Filters;
  findByEmail: User;
  findByPath: User;
  link: Link;
  me: Me;
  profile: User;
  school: School;
  schools: PaginatedSchool;
  subscription: Array<Subscription>;
  user: User;
  users: PaginatedUser;
}

export type QueryBookArgs = {
  id: Scalars['ID']['input'];
};

export type QueryBooksArgs = {
  filters: BooksFilters;
  orderBy?: InputMaybe<Array<Scalars['String']['input']>>;
  page: GetPage;
};

export type QueryDistrictArgs = {
  id: Scalars['ID']['input'];
};

export type QueryDistrictsArgs = {
  filters?: InputMaybe<DistrictFilters>;
  page?: InputMaybe<GetPage>;
};

export type QueryFindByEmailArgs = {
  email: Scalars['String']['input'];
};

export type QueryFindByPathArgs = {
  path: Scalars['String']['input'];
};

export type QueryLinkArgs = {
  id: Scalars['ID']['input'];
};

export type QuerySchoolArgs = {
  id: Scalars['ID']['input'];
};

export type QuerySchoolsArgs = {
  filters?: InputMaybe<SchoolFilters>;
  page?: InputMaybe<GetPage>;
};

export type QuerySubscriptionArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  unitId: Scalars['ID']['input'];
};

export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};

export type QueryUsersArgs = {
  filters?: InputMaybe<UserFilters>;
  page?: InputMaybe<GetPage>;
};

export enum Role {
  ADMIN = 'ADMIN',
  DISTRICT_ADMIN = 'DISTRICT_ADMIN',
  SCHOOL_ADMIN = 'SCHOOL_ADMIN',
  TEACHER = 'TEACHER'
}

export interface School {
  __typename?: 'School';
  active: Scalars['Boolean']['output'];
  address: Scalars['String']['output'];
  city: Scalars['String']['output'];
  district: District;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  state: Scalars['String']['output'];
  zip: Scalars['String']['output'];
}

export interface SchoolFilters {
  districtIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
}

export interface Series {
  __typename?: 'Series';
  id: Scalars['Int']['output'];
  key: SeriesEnum;
  value: Scalars['String']['output'];
}

export enum SeriesEnum {
  DESPEGANDO = 'DESPEGANDO',
  DESPEGANDO_FONETICA = 'DESPEGANDO_FONETICA',
  EXPLORA_MUNDOS = 'EXPLORA_MUNDOS',
  FLYING_START = 'FLYING_START',
  FLYING_START_PHONICS = 'FLYING_START_PHONICS',
  WORLDWISE = 'WORLDWISE'
}

export interface Stage {
  __typename?: 'Stage';
  id: Scalars['Int']['output'];
  key: StageEnum;
  value: Scalars['String']['output'];
}

export enum StageEnum {
  ADVANCED_FLUENT_Q_S = 'ADVANCED_FLUENT_Q_S',
  ADVANCED_FLUENT_T_V = 'ADVANCED_FLUENT_T_V',
  EARLY = 'EARLY',
  EARLY_EMERGENT = 'EARLY_EMERGENT',
  EARLY_FLUENT = 'EARLY_FLUENT',
  EMERGENT = 'EMERGENT',
  FLUENT = 'FLUENT',
  FLUENT_PLUS = 'FLUENT_PLUS',
  FONETICA_STAGE_ONE_MODULE_ONE = 'FONETICA_STAGE_ONE_MODULE_ONE',
  FONETICA_STAGE_ONE_MODULE_THREE = 'FONETICA_STAGE_ONE_MODULE_THREE',
  FONETICA_STAGE_ONE_MODULE_TWO = 'FONETICA_STAGE_ONE_MODULE_TWO',
  FONETICA_STAGE_THREE_MODULE_SEVEN = 'FONETICA_STAGE_THREE_MODULE_SEVEN',
  FONETICA_STAGE_THREE_MODULE_SIX = 'FONETICA_STAGE_THREE_MODULE_SIX',
  FONETICA_STAGE_TWO_MODULE_FIVE = 'FONETICA_STAGE_TWO_MODULE_FIVE',
  FONETICA_STAGE_TWO_MODULE_FOUR = 'FONETICA_STAGE_TWO_MODULE_FOUR',
  PHONICS_STAGE_ONE_MODULE_ONE = 'PHONICS_STAGE_ONE_MODULE_ONE',
  PHONICS_STAGE_ONE_MODULE_THREE = 'PHONICS_STAGE_ONE_MODULE_THREE',
  PHONICS_STAGE_ONE_MODULE_TWO = 'PHONICS_STAGE_ONE_MODULE_TWO',
  PHONICS_STAGE_THREE_MODULE_SEVEN = 'PHONICS_STAGE_THREE_MODULE_SEVEN',
  PHONICS_STAGE_THREE_MODULE_SIX = 'PHONICS_STAGE_THREE_MODULE_SIX',
  PHONICS_STAGE_TWO_MODULE_FIVE = 'PHONICS_STAGE_TWO_MODULE_FIVE',
  PHONICS_STAGE_TWO_MODULE_FOUR = 'PHONICS_STAGE_TWO_MODULE_FOUR',
  SHARED_READING = 'SHARED_READING',
  TRANSITIONAL = 'TRANSITIONAL'
}

export interface Subscription {
  __typename?: 'Subscription';
  active: Scalars['Boolean']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  from: Scalars['DateOnly']['output'];
  id: Scalars['ID']['output'];
  items: Array<SubscriptionItem>;
  to: Scalars['DateOnly']['output'];
  unitId: Scalars['ID']['output'];
}

export interface SubscriptionItem {
  __typename?: 'SubscriptionItem';
  series: Series;
  stage: Stage;
}

export interface SubscriptionItemInput {
  series: SeriesEnum;
  stage: StageEnum;
}

export interface Token {
  __typename?: 'Token';
  accessToken: Scalars['String']['output'];
}

export interface UpdateDistrictInput {
  externalId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
}

export interface UpdatePasswordInput {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
}

export interface UpdateSchoolInput {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  districtId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  state: Scalars['String']['input'];
  zip: Scalars['String']['input'];
}

export interface UpdateSubscriptionInput {
  from: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
  items: Array<SubscriptionItemInput>;
  to: Scalars['DateTime']['input'];
}

export interface UpdateUserInput {
  assignedRole: AssignedRoleInput;
  classroom?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  gradeLevel?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastName: Scalars['String']['input'];
  loginPath?: InputMaybe<Scalars['String']['input']>;
  schoolBuilding?: InputMaybe<Scalars['String']['input']>;
}

export interface User {
  __typename?: 'User';
  assignedRole: AssignedRole;
  classroom?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<ExternalId>;
  firstName: Scalars['String']['output'];
  gradeLevel?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  loginPath?: Maybe<Scalars['String']['output']>;
  schoolBuilding?: Maybe<Scalars['String']['output']>;
  status: UserStatus;
  termsAccepted: Scalars['Boolean']['output'];
}

export interface UserFilters {
  roles?: InputMaybe<Array<Role>>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<UserStatus>>;
  unitIds?: InputMaybe<Array<Scalars['ID']['input']>>;
}

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  CREATED = 'CREATED',
  INACTIVE = 'INACTIVE'
}
