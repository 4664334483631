import React, { FC } from 'react';

import LandingPage from '@okapi/components/LandingPage';

const ChildrenPolicy: FC = () => (
  <LandingPage title="Okapi Children's Privacy Policy">
    <div>
      <p>
        <strong>Effective Date: September 2020</strong>
      </p>

      <p>
        Okapi Educational Publishing, Inc. (“Okapi” or “we”, “us” or “our”) markets and sells products and services (collectively
        “<strong>Services</strong>”) to adults who purchase or otherwise subscribe to these Services, as further described below,
        through Okapi’s applications, online services or Okapi websites (including but not limited to,{' '}
        <a href="https://myokapi.com">https://myokapi.com</a>,&nbsp;{' '}
        <a href="https://flyingstarttoliteracy.com">https://flyingstarttoliteracy.com</a>,&nbsp;{' '}
        <a href="https://despegando-hacia-la-lectura.com">https://despegando-hacia-la-lectura.com</a>,&nbsp;{' '}
        <a href="https://okapi-bookrooms.com">https://okapi-bookrooms.com</a>,&nbsp;{' '}
        <a href="https://worldwise-reading.com">https://worldwise-reading.com</a>,&nbsp;{' '}
        <a href="https://exploramundos-reading.com">https://exploramundos-reading.com</a>,&nbsp;{' '}
        <a href="https://thinkokapi.com">https://thinkokapi.com</a>,&nbsp;{' '}
        <a href="https://okapi-digital-literacy.com">https://okapi-digital-literacy.com</a> &nbsp; (collectively “
        <strong>Websites</strong>”).&nbsp;&nbsp;These Services may then be used by children, some of whom may be under the age of
        13, at the direction and under the supervision of these adults.&nbsp; As a result of these Services, the Websites may
        incidentally collect personal information from children under the age of 13 as specifically described below.
      </p>

      <p>
        This Children’s Privacy Policy describes the information collected from children, how Okapi uses this information, and
        other practices with respect to the information, as set out below.
      </p>

      <p>
        This Children’s Privacy Policy shall govern over any conflicting provisions in Okapi’s general Privacy Policy found at:{' '}
        <a href="https://myokapi.com/privacy-policy">
          <strong>https://myokapi.com/privacy-policy</strong>
        </a>
        .&nbsp; For inquiries about this Children’s Privacy Policy or our use of information collected from children, please
        contact us at:
      </p>

      <p>
        <strong>
          <em>How to Contact Us</em>
        </strong>
      </p>

      <p>
        If you have any questions, concerns or complaints about our Children’s Privacy Policy or our data collection or processing
        practices, or if you want to report any suspected security violations to us, please contact us at the following address or
        email address:
      </p>

      <p>Privacy Officer</p>

      <p>Okapi Educational Publishing, Inc.</p>

      <p>42381 Rio Nedo, Temecula, CA 92590</p>

      <p>privacy@myokapi.com&nbsp;</p>

      <p>866-652-7436</p>

      <p>
        <strong>
          <em>Services We Provide</em>
        </strong>
      </p>

      <p>We offer the following services (“Services”) on our Websites:</p>

      <ul>
        <li>
          We provide products,<strong> </strong>services, tools and resources to school districts, schools and educators to help
          support and facilitate the education process;
        </li>
      </ul>

      <ul>
        <li>Our Websites offer tips and practical guidance for literacy teachers;</li>
      </ul>

      <ul>
        <li>
          We offer on-line educational facilitation tools, including but not limited to, Flying Start to Literacy Activities and
          Worldwise – Content-Based Investigations.&nbsp; Teachers use these tools inside and outside the classroom to help
          students meet educational goals.
        </li>
      </ul>

      <ul>
        <li>We work with independent sales representative to make our tools and resources available to educators.</li>
      </ul>

      <p>
        <strong>
          <em>Information We Collect&nbsp;</em>
        </strong>
      </p>

      <p>
        We market and sell our educational materials to school districts who purchase or otherwise subscribe to our
        Services.&nbsp; These educational materials can be distributed by teachers to certain of their students who may be
        children under the age of 13, under the direction and supervision of their teachers.&nbsp; Okapi is unaware, however, of
        when an individual is under the age of 13 and who may be using the Websites or materials distributed by teachers, as no
        log in is required for any student.&nbsp; The only incidental information we may receive from children under the age of 13
        may be certain types of information automatically collected when a student accesses the Websites, such as browser type and
        internet protocol (IP) address.&nbsp;
      </p>

      <p>
        While our system may automatically collect this incidental usage information, we would not be able to identify or match
        this information with a child’s other personal information; however, we reserve the right to review this information
        further under certain exigent circumstances (including, but not limited to, reporting to law enforcement authorities, in
        response to subpoenas or to enforce our Terms of Use). The information may also be used to diagnose Website technical
        problems, facilitate use of our Website, or track aggregate Website traffic patterns, but no effort is made to identify
        any other personal information in such cases.
      </p>

      <p>
        Okapi does&nbsp;not&nbsp;use, transfer, share, or sell any of the information collected as described above from children
        for commercial purposes.&nbsp;&nbsp;
      </p>

      <p>
        <strong>
          <em>How We Use Information Collected</em>
        </strong>
      </p>

      <p>
        <strong>
          <em>Contract Fulfillment</em>
        </strong>
      </p>

      <p>
        We contract with schools, school districts and school boards (collectively “<strong>Schools</strong>”) directly for the
        use of the Services on our Websites. &nbsp;&nbsp;We provide Schools with full notice of our collection, use and disclosure
        practices prior to the purchase of any products or Services.&nbsp;&nbsp;
      </p>

      <p>
        <strong>
          <em>Children’s Information&nbsp;&nbsp;</em>
        </strong>
      </p>

      <p>
        Our Websites are not intended for use by children, except for the limited purposes described above.&nbsp; Children under
        thirteen (13) may not register or set up any account on our Websites, or send any information about themselves to us
        through the Websites.&nbsp; We take measures to restrict account creation on our Websites to adults only, including a
        verification process that any registrant is over the age of 13.&nbsp; Therefore, we do not collect personally identifiable
        data from any person that we know to be under the age of thirteen (13). If we learn that a child under thirteen (13) has
        submitted information to us through any part of our Websites, we will attempt to delete this information as soon as
        practical.&nbsp;&nbsp;
      </p>

      <p>
        <strong>
          <em>Information Security</em>
        </strong>
      </p>

      <p>Please refer to the general Privacy Policy for a description of the measures Okapi takes to protect information.</p>

      <p>
        <strong>
          <em>Changes to this Children’s Privacy Policy</em>
        </strong>
      </p>

      <p>
        This Children’s Privacy Policy may be updated from time to time for any reason.&nbsp; If you have provided your email
        address, we will notify you regarding any material changes to this Children’s Privacy Policy.&nbsp; You can then decide
        whether you would like to continue to use the Services.&nbsp; If you have not provided us with your email address, we will
        notify you of any changes to our Children’s Privacy Policy by posting the new Children’s Privacy Policy and changing the
        “Effective” date above.&nbsp; You should consult this Children’s Privacy Policy regularly for any changes.
      </p>
    </div>
  </LandingPage>
);

export default ChildrenPolicy;
