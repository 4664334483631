import { Card, Form, Typography } from 'antd';
import type { FC, ReactNode } from 'react';

import CheckboxGroup from '@okapi/components/Form/CheckboxGroup';
import MultiSelect from '@okapi/components/Form/MultiSelect';
import { Option } from '@okapi/types';
import useTracking from '@okapi/useTracking';

import { initialFilters } from '../../BookList/BookList';
import { BooksFilters, Filters as FiltersType } from '../../types';
import { FilterConfig, prepareFilters } from './helpers';
import styles from './styles.module.less';

interface Props {
  filterOptions: FiltersType;
  initialValues: BooksFilters;
  onChange: (filters: BooksFilters) => void;
  onClearFilters: () => void;
}

const Filters: FC<Props> = ({ initialValues, filterOptions, onChange, onClearFilters }) => {
  const { trackEvent } = useTracking();
  const [form] = Form.useForm();

  const filters: FilterConfig[] = prepareFilters(filterOptions);

  const handleChange = (_: unknown, values: BooksFilters): void => onChange(values);

  const handleClear = (): void => {
    form.setFieldsValue(initialFilters);

    onClearFilters();
  };

  const handleCheckboxGroupChange = (values: number[], options: Option<number>[], filterName: string): void => {
    const selectedFilters = values.map((value) => options.find((option) => option?.value === value)?.label).join('|');

    trackEvent({
      category: 'BookFilters',
      action: filterName,
      label: selectedFilters
    });
  };

  const renderFilter = ({ isMultiSelect = false, label, name, options, width }: FilterConfig): ReactNode => {
    if (isMultiSelect) {
      return (
        <div key={name}>
          <div className={styles.filterTitle}>{label}</div>
          <MultiSelect dropdownWidth={width} name={name} options={options} />
        </div>
      );
    }

    return (
      <div key={name}>
        <div className={styles.filterTitle}>{label}</div>
        <CheckboxGroup<number>
          name={name}
          onChange={(values) => handleCheckboxGroupChange(values, options, label)}
          options={options}
        />
      </div>
    );
  };

  return (
    <Card size="small">
      <div className={styles.headline}>
        <div>Filter by</div>
        <Typography.Link className={styles.clearLink} onClick={handleClear}>
          Clear All
        </Typography.Link>
      </div>
      <Form form={form} initialValues={initialValues} onValuesChange={handleChange}>
        {filters.map(renderFilter)}
      </Form>
    </Card>
  );
};

export default Filters;
