import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import NotAuthorized from '@okapi/components/NotAuthorized';
import Spinner from '@okapi/components/Spinner';

import { useAuthorizeCleverMutation } from '../graphql';

interface Props {}

const CleverOAuth: FC<Props> = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [hasError, setHasError] = useState<boolean>(false);
  const [authorizeClever] = useAuthorizeCleverMutation();

  const code = searchParams.get('code') || '';

  const authorize = async () => {
    try {
      const { data } = await authorizeClever({
        variables: {
          code
        }
      });

      const accessToken: string = data?.clever?.accessToken || '';

      localStorage.setItem('accessToken', accessToken);

      navigate('/', {
        state: {
          loginMethod: 'clever'
        }
      });
    } catch {
      setHasError(true);
    }
  };

  useEffect(() => {
    if (code) {
      authorize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!code || hasError) {
    return <NotAuthorized />;
  }

  return <Spinner />;
};

export default CleverOAuth;
