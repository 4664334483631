import { App, Button, Card, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import Pagination from '@okapi/components/Pagination';
import QueryHandler from '@okapi/components/QueryHandler';
import useAccount from '@okapi/domains/Auth/hooks/useAccount';
import { Role } from '@okapi/graphql/types.generated';
import { getPaginationParams } from '@okapi/utils/pagination';

import { useActivateSchoolMutation, useDeactivateSchoolMutation, useSchoolsQuery } from '../graphql';
import { getColumns } from '../tableConfig';
import styles from './styles.module.less';

interface Props {}

const SchoolList: FC<Props> = () => {
  const { notification } = App.useApp();
  const { unitId, role } = useAccount();
  const [page, setPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [activateSchool] = useActivateSchoolMutation();
  const [deactivateSchool] = useDeactivateSchoolMutation();
  const { data, error, loading } = useSchoolsQuery({
    variables: {
      ...getPaginationParams(page),
      filters: {
        ...(role === Role.DISTRICT_ADMIN ? { districtIds: [unitId] } : {}),
        search: searchTerm
      }
    },
    fetchPolicy: 'cache-and-network'
  });

  const schools = data?.schools?.nodes || [];
  const totalCount = data?.schools?.totalCount || 0;

  const handleDeactivate = async (id: string) => {
    try {
      await deactivateSchool({ variables: { unitId: id }, refetchQueries: ['schools'] });

      notification.success({ message: 'Success', description: 'School has been deactivated.' });
    } catch {
      notification.error({ message: 'Error', description: 'Something went wrong.' });
    }
  };

  const handleActivate = async (id: string) => {
    try {
      await activateSchool({ variables: { unitId: id }, refetchQueries: ['schools'] });

      notification.success({ message: 'Success', description: 'School has been activated.' });
    } catch {
      notification.error({ message: 'Error', description: 'Something went wrong.' });
    }
  };

  const columns = getColumns({ onActivate: handleActivate, onDeactivate: handleDeactivate });

  const handleSearch = (val: string) => {
    setSearchTerm(val);
    setPage(1);
  };

  return (
    <>
      <Helmet>
        <title>Schools</title>
      </Helmet>
      <Card
        extra={
          <Link to="/schools/add">
            <Button size="large" type="primary">
              Add new school
            </Button>
          </Link>
        }
        title="Schools"
      >
        <Search
          allowClear={true}
          className={styles.searchWrapper}
          onSearch={handleSearch}
          placeholder="Search for a school name"
          size="large"
        />
        <QueryHandler data={data?.schools.nodes} error={!!error} isLoading={loading}>
          <Table columns={columns} dataSource={schools} pagination={false} rowKey="id" scroll={{ x: 700 }} tableLayout="auto" />
          <Pagination currentPage={page} onChange={setPage} totalCount={totalCount} />
        </QueryHandler>
      </Card>
    </>
  );
};

export default SchoolList;
