import { App, Button, Card, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import { FC, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import Pagination from '@okapi/components/Pagination';
import QueryHandler from '@okapi/components/QueryHandler';
import { getPaginationParams } from '@okapi/utils/pagination';
import { Nullable } from '@okapi/utils/typescript';

import useAccount from '../Auth/hooks/useAccount';
import { User } from '../Users';
import { useActivateUserMutation, useDeactivateUsersMutation, useResendInvitationEmailMutation } from '../Users/graphql';
import AddUser from './AddUser';
import EditUser from './EditUser';
import { useAllUsersQuery } from './graphql';
import styles from './styles.module.less';
import { getColumns } from './tableConfig';

const UserManagement: FC = () => {
  const { notification } = App.useApp();
  const [page, setPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [userToEdit, setUserToEdit] = useState<Nullable<User>>(null);
  const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);
  const { userProfile } = useAccount();

  const [activateUser] = useActivateUserMutation();
  const [resendInvitationEmail] = useResendInvitationEmailMutation();
  const [deactivateUser] = useDeactivateUsersMutation();
  const { data, loading, error } = useAllUsersQuery({
    variables: {
      ...getPaginationParams(page),
      filters: {
        roles: [],
        unitIds: [],
        search: searchTerm
      }
    },
    fetchPolicy: 'cache-and-network'
  });

  const users = data?.users?.nodes || [];
  const totalCount = data?.users?.totalCount || 0;

  const handleEdit = (user: User) => {
    setUserToEdit(user);
  };

  const handleSearch = (val: string) => {
    setSearchTerm(val);
    setPage(1);
  };

  const handleActivate = async (id: string) => {
    try {
      await activateUser({ variables: { userId: id }, refetchQueries: ['allUsers'] });

      notification.success({ message: 'Success', description: 'User has been activated.' });
    } catch {
      notification.error({ message: 'Error', description: 'Something went wrong.' });
    }
  };

  const handleDeactivate = async (id: string) => {
    try {
      await deactivateUser({ variables: { userIds: [id] }, refetchQueries: ['allUsers'] });

      notification.success({ message: 'Success', description: 'User has been deactivated.' });
    } catch {
      notification.error({ message: 'Error', description: 'Something went wrong.' });
    }
  };

  const handleResendInvitationEmail = async (userId: string) => {
    try {
      await resendInvitationEmail({ variables: { userId }, refetchQueries: ['allUsers'] });

      notification.success({ message: 'Success', description: 'Invitation email has been sent.' });
    } catch {
      notification.error({ message: 'Error', description: 'Something went wrong.' });
    }
  };

  const columns = getColumns({
    onEdit: handleEdit,
    onActivate: handleActivate,
    onDeactivate: handleDeactivate,
    onResendInvitationEmail: handleResendInvitationEmail,
    currentUserId: userProfile.id
  });

  const closeAddUserModal = (): void => setShowAddUserModal(false);
  const closeEditUserModal = (): void => setUserToEdit(null);

  return (
    <>
      <Helmet>
        <title>User Management</title>
      </Helmet>
      <Card
        extra={
          <Button onClick={() => setShowAddUserModal(true)} size="large" type="primary">
            Add a new user
          </Button>
        }
        title="User Management"
      >
        <Search
          allowClear={true}
          className={styles.searchWrapper}
          data-testid="search-input"
          onSearch={handleSearch}
          placeholder="Search for a first name, last name and email"
          size="large"
        />
        <QueryHandler data={users} error={!!error} isLoading={loading}>
          <Table
            columns={columns}
            data-testid="users-table"
            dataSource={users}
            pagination={false}
            rowKey="id"
            scroll={{ x: 700 }}
            tableLayout="auto"
          />
          <Pagination currentPage={page} onChange={setPage} totalCount={totalCount} />
        </QueryHandler>
      </Card>

      {showAddUserModal && <AddUser onClose={closeAddUserModal} onSuccess={closeAddUserModal} />}
      {userToEdit && <EditUser onClose={closeEditUserModal} onSuccess={closeEditUserModal} user={userToEdit} />}
    </>
  );
};

export default UserManagement;
