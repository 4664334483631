import { App, Button, Card, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import Pagination from '@okapi/components/Pagination';
import QueryHandler from '@okapi/components/QueryHandler';
import { getPaginationParams } from '@okapi/utils/pagination';

import AddDistrict from '../AddDistrict';
import { useActivateDistrictMutation, useDeactivateDistrictMutation, useDistrictsQuery } from '../graphql';
import { getColumns } from '../tableConfig';
import styles from './styles.module.less';

const Districts: FC = () => {
  const { notification } = App.useApp();
  const [showAddDistrictModal, setShowAddDistrictModal] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [activateDistrict] = useActivateDistrictMutation();
  const [deactivateDistrict] = useDeactivateDistrictMutation();
  const { data, error, loading } = useDistrictsQuery({
    variables: { ...getPaginationParams(page), filters: { search: searchTerm } },
    fetchPolicy: 'cache-and-network'
  });

  const districts = data?.districts?.nodes || [];
  const totalCount = data?.districts?.totalCount || 0;

  const handleActivate = async (id: string) => {
    try {
      await activateDistrict({ variables: { unitId: id }, refetchQueries: ['districts'] });

      notification.success({ message: 'Success', description: 'District has been activated.' });
    } catch {
      notification.error({ message: 'Error', description: 'Something went wrong.' });
    }
  };

  const handleDeactivate = async (id: string) => {
    try {
      await deactivateDistrict({ variables: { unitId: id }, refetchQueries: ['districts'] });

      notification.success({ message: 'Success', description: 'District has been deactivated.' });
    } catch {
      notification.error({ message: 'Error', description: 'Something went wrong.' });
    }
  };

  const columns = getColumns({ onActivate: handleActivate, onDeactivate: handleDeactivate });

  const hideModal = () => setShowAddDistrictModal(false);

  const handleSearch = (val: string) => {
    setSearchTerm(val);
    setPage(1);
  };

  return (
    <>
      <Helmet>
        <title>Districts</title>
      </Helmet>
      <Card
        extra={
          <Button onClick={() => setShowAddDistrictModal(true)} size="large" type="primary">
            Add new district
          </Button>
        }
        title="Districts"
      >
        <Search
          allowClear={true}
          className={styles.searchWrapper}
          onSearch={handleSearch}
          placeholder="Search for a district name"
          size="large"
        />
        <QueryHandler data={data?.districts.nodes} error={!!error} isLoading={loading}>
          <Table columns={columns} dataSource={districts} pagination={false} rowKey="id" scroll={{ x: 700 }} tableLayout="auto" />
          <Pagination currentPage={page} onChange={setPage} totalCount={totalCount} />
        </QueryHandler>
      </Card>
      {showAddDistrictModal && <AddDistrict onCancel={hideModal} onSuccess={hideModal} />}
    </>
  );
};

export default Districts;
