import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import NotAuthorized from '@okapi/components/NotAuthorized';
import Spinner from '@okapi/components/Spinner';

import { useAuthorizeEdlinkMutation } from '../graphql';

const EdlinkOAuth: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [hasError, setHasError] = useState<boolean>(false);
  const [authorizeEdlink] = useAuthorizeEdlinkMutation();

  const code = searchParams.get('code') || '';
  const state = searchParams.get('state');

  const authorize = async () => {
    try {
      const { data } = await authorizeEdlink({
        variables: {
          code,
          state
        }
      });

      const accessToken = data?.edlink?.accessToken || '';

      localStorage.setItem('accessToken', accessToken);

      navigate('/', {
        state: {
          loginMethod: 'edlink'
        }
      });
    } catch {
      setHasError(true);
    }
  };

  useEffect(() => {
    if (code) {
      authorize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!code || hasError) {
    return <NotAuthorized />;
  }

  return <Spinner />;
};

export default EdlinkOAuth;
