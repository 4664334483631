import { createContext } from 'react';

import { Role, User } from '@okapi/graphql/types.generated';
import { Nullable } from '@okapi/utils/typescript';

export interface UserContextData {
  districtName: string;
  logout: () => void;
  role: Nullable<Role>;
  unitId: string;
  userProfile: Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>;
}

export const initialContext: UserContextData = {
  districtName: '',
  userProfile: {
    id: '',
    firstName: '',
    lastName: '',
    email: ''
  },
  unitId: '',
  role: null,
  logout: () => {}
};

const UserContext = createContext(initialContext);

export default UserContext;
