import { message } from 'antd';
import copy from 'copy-to-clipboard';

import { useCreateBookLinkMutation } from '../graphql';
import { getSharedBookUrl } from '../helpers';

export const useShareBook = () => {
  const [shareBook] = useCreateBookLinkMutation();

  const handleShare = async (bookId: string, resourceType: 'book' | 'video' = 'book'): Promise<void> => {
    try {
      const { data: linkData } = await shareBook({ variables: { bookId } });

      const token = linkData?.createLink?.id || '';
      const url = getSharedBookUrl(token, resourceType);

      copy(url);

      message.success('The link has been copied, now open a new email, paste the link and send to your students.');
    } catch {
      message.error('Something went wrong.');
    }
  };

  return {
    shareBook: handleShare
  };
};
