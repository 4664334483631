import { isEmpty, isObject } from 'lodash';
import React, { FC, ReactNode } from 'react';

import NoResults from '../NoResults';
import Spinner from '../Spinner';

interface Props {
  children: ReactNode;
  data: unknown;
  error: boolean;
  isLoading: boolean;
  noResults?: ReactNode;
}

const QueryHandler: FC<Props> = (props) => {
  const { children, error, data, isLoading, noResults = <NoResults /> } = props;

  const hasData: boolean = Array.isArray(data) || isObject(data) ? !isEmpty(data) : !!data;

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <NoResults tagline="Please try again later" text="Something went wrong." />;
  }

  if (!hasData) {
    return noResults;
  }

  return children;
};

export default QueryHandler;
