import { Card, Collapse, Typography } from 'antd';
import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';

import { getFrequentlyAskedQuestions } from './helpers';
import odlImage from './images/odl.png';
import styles from './styles.module.less';

const Faq: FC = () => (
  <>
    <Helmet>
      <title>Frequently Asked Questions</title>
      <script src="https://player.vimeo.com/api/player.js" />
    </Helmet>
    <Card className={styles.wrapper}>
      <div className={styles.videoContainer}>
        <iframe
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen={true}
          className={styles.video}
          frameBorder="0"
          src="https://player.vimeo.com/video/1001325510?h=0cf5d0d47e&badge=0&autopause=0&autoplay=1&player_id=0&app_id=58479"
          title="Okapi Digital Literacy Getting Started"
        />
      </div>
      <Typography.Title level={3}>About Okapi Digital Literacy &trade;</Typography.Title>
      <p>
        Okapi Digital Literacy is a digital book platform that enables teachers to view and share Okapi’s instructional content
        and resources with students. The platform provides books and teaching support for these flagship programs: Flying Start to
        Literacy: PHONICS™, Flying Start to Literacy™, Despegando hacia la lectura™, WorldWise: Content-based Learning™,
        ExploraMundos™, and Biliteracy para todos™.
      </p>

      <img alt="Okapi Digital Literacy" className={styles.image} src={odlImage} />

      <Typography.Title level={3}>Page-Flip eBooks</Typography.Title>
      <div>
        <p>
          Okapi Digital Literacy™ eBooks can be viewed on Windows PC, Mac, Chromebooks, iPad, iPhone, Android and more. The
          platform has been developed with responsive design to facilitate viewing on all devices.
        </p>
      </div>

      <Typography.Title level={3}>Frequently Asked Questions (FAQ)</Typography.Title>

      <Collapse bordered={false} className={styles.accordions} items={getFrequentlyAskedQuestions()} />
    </Card>
  </>
);

export default Faq;
