import { App, Button, Table } from 'antd';
import React, { FC, useState } from 'react';

import QueryHandler from '@okapi/components/QueryHandler';
import { Nullable } from '@okapi/utils/typescript';

import AddSubscription from './AddSubscription';
import EditSubscription from './EditSubscription';
import { useDeleteSubscription, useSubscriptionQuery } from './graphql';
import styles from './styles.module.less';
import { getColumns } from './tableConfig';
import { Subscription } from './types';

interface Props {
  unitId: string;
}

const Subscriptions: FC<Props> = ({ unitId }) => {
  const { notification } = App.useApp();
  const [subscriptionToEdit, setSubscriptionToEdit] = useState<Nullable<Subscription>>(null);
  const [showAddSubscriptionModal, setShowAddSubscriptionModal] = useState<boolean>(false);
  const [showEditSubscriptionModal, setShowEditSubscriptionModal] = useState<boolean>(false);

  const [deleteSubscription] = useDeleteSubscription();
  const { data, loading, error } = useSubscriptionQuery({
    variables: { active: undefined, unitId },
    fetchPolicy: 'cache-and-network'
  });

  const subscriptions = data?.subscription || [];

  const handleEdit = (subscription: Subscription) => {
    setSubscriptionToEdit(subscription);
    setShowEditSubscriptionModal(true);
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteSubscription({ variables: { id }, refetchQueries: ['subscription'] });

      notification.success({ message: 'Success', description: 'Subscription has been removed.' });
    } catch {
      notification.error({ message: 'Error', description: 'Something went wrong.' });
    }
  };

  const columns = getColumns({ onEdit: handleEdit, onDelete: handleDelete, unitId });

  const closeAddUserModal = () => setShowAddSubscriptionModal(false);
  const closeEditUserModal = () => setShowEditSubscriptionModal(false);

  return (
    <>
      <div className={styles.buttonWrapper}>
        <Button onClick={() => setShowAddSubscriptionModal(true)} size="large" type="primary">
          Add new subscription
        </Button>
      </div>
      <QueryHandler data={subscriptions} error={!!error} isLoading={loading}>
        <Table
          columns={columns}
          dataSource={subscriptions}
          pagination={false}
          rowKey="id"
          scroll={{ x: 700 }}
          tableLayout="auto"
        />
      </QueryHandler>
      {showAddSubscriptionModal && <AddSubscription onClose={closeAddUserModal} onSuccess={closeAddUserModal} unitId={unitId} />}
      {showEditSubscriptionModal && subscriptionToEdit && (
        <EditSubscription onClose={closeEditUserModal} onSuccess={closeEditUserModal} subscription={subscriptionToEdit} />
      )}
    </>
  );
};

export default Subscriptions;
