import { Store } from 'antd/lib/form/interface';
import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { To, useLocation, useNavigate } from 'react-router';

import Alert from '@okapi/components/Alert';

import AuthWrapper from '../components/AuthWrapper';
import { useLogInMutation } from '../graphql';
import LoginForm from './LoginForm';

const Login: FC = () => {
  const [hasServerError, setServerError] = useState<boolean>(false);
  const [logIn] = useLogInMutation();
  const { state } = useLocation();
  const navigate = useNavigate();

  const navigateUrl: To = state?.backUrl?.pathname ? { pathname: state.backUrl.pathname, search: state.backUrl.search } : '/';

  const handleSubmit = async ({ email, password }: Store) => {
    try {
      setServerError(false);

      const { data } = await logIn({
        variables: {
          email,
          password
        }
      });

      const accessToken = data?.login?.accessToken || '';

      localStorage.setItem('accessToken', accessToken);

      navigate(navigateUrl, {
        state: {
          loginMethod: 'password'
        }
      });
    } catch {
      setServerError(true);
    }
  };

  return (
    <AuthWrapper>
      <Helmet>
        <title>Login</title>
      </Helmet>
      {hasServerError && <Alert message="Login failed. Email or password is not correct." type="error" />}
      <LoginForm onSubmit={handleSubmit} />
    </AuthWrapper>
  );
};

export default Login;
