import { App } from 'antd';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router';

import { FormHeadline } from '@okapi/components/Form';

import AuthWrapper from '../components/AuthWrapper';
import UpdatePasswordForm from '../components/UpdatePasswordForm';
import { useLogInMutation, useUpdatePasswordMutation } from '../graphql';

interface UrlParams {
  token: string;
}

const Registration: FC = () => {
  const { notification } = App.useApp();
  const navigate = useNavigate();
  const { token } = useParams<{ token: string }>() as UrlParams;
  const [logIn] = useLogInMutation();
  const [updatePassword] = useUpdatePasswordMutation();

  const handleSubmit = async ({ password }: { password: string }) => {
    try {
      const { data } = await updatePassword({
        variables: {
          passwordInput: {
            password,
            token
          }
        }
      });

      const { data: loginData } = await logIn({
        variables: {
          email: data?.updatePassword?.email || '',
          password
        }
      });

      localStorage.setItem('accessToken', loginData?.login?.accessToken || '');

      notification.success({ message: 'You account has been created.' });

      navigate('/', {
        state: {
          loginMethod: 'password'
        }
      });
    } catch {
      notification.error({ message: 'Something went wrong' });
    }
  };

  return (
    <AuthWrapper>
      <Helmet>
        <title>Registration</title>
      </Helmet>
      <FormHeadline
        tagline="Please enter your new password twice so we can verify you typed it in correctly."
        text="Registration"
      />
      <UpdatePasswordForm isRegistration={true} onSubmit={handleSubmit} showBackLink={false} />
    </AuthWrapper>
  );
};

export default Registration;
