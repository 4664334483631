import { SeriesEnum, StageEnum } from '@okapi/graphql/types.generated';
import { Optional } from '@okapi/utils/typescript';

export const AWS_HOSTNAME = 'https://okapi-digital-literacy-assets.s3.amazonaws.com';

export const getBookThumbnail = (isbn: string): string => `${AWS_HOSTNAME}/book-thumbnails/${isbn}.jpg`;

export const getBookUrl = (assetId: string): string => `${AWS_HOSTNAME}/books/${assetId}/index.html`;

export const getVideoUrl = (videoAssetId: string): string => `${AWS_HOSTNAME}/videos/${videoAssetId}.mp4`;

export const getRawResourceUrl = (
  assetId: Optional<string>,
  resourceType: 'lessonPlan' | 'runningRecord' | 'teachingNotes'
): string => {
  const config = {
    lessonPlan: 'raw-lesson-plans',
    runningRecord: 'raw-running-records',
    teachingNotes: 'raw-teaching-notes'
  };

  const directoryName: string = config[resourceType];

  if (!assetId) {
    return '';
  }

  return `${AWS_HOSTNAME}/${directoryName}/${assetId}.pdf`;
};

export const getSharedBookUrl = (token: string, resourceType: 'book' | 'video'): string => {
  const { protocol, hostname, port } = window.location;
  const origin: string = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
  const suffix: string = resourceType === 'video' ? '/video' : '';

  return `${origin}/book/${token}${suffix}`;
};

export const seriesLabelMapping: Record<SeriesEnum, string> = {
  [SeriesEnum.FLYING_START_PHONICS]: 'Flying Start to Literacy: Phonics',
  [SeriesEnum.FLYING_START]: 'Flying Start to Literacy',
  [SeriesEnum.DESPEGANDO_FONETICA]: 'Despegando hacia la lectura FONÉTICA',
  [SeriesEnum.DESPEGANDO]: 'Despegando hacia la lectura',
  [SeriesEnum.WORLDWISE]: 'WorldWise',
  [SeriesEnum.EXPLORA_MUNDOS]: 'ExploraMundos'
};

export const stageLabelMapping: Record<StageEnum, string> = {
  [StageEnum.ADVANCED_FLUENT_Q_S]: 'Advanced Fluent (Q-S)',
  [StageEnum.ADVANCED_FLUENT_T_V]: 'Advanced Fluent (T-V)',
  [StageEnum.EARLY_EMERGENT]: 'Early Emergent',
  [StageEnum.EARLY_FLUENT]: 'Early Fluent',
  [StageEnum.EARLY]: 'Early',
  [StageEnum.EMERGENT]: 'Emergent',
  [StageEnum.FLUENT_PLUS]: 'Fluent Plus',
  [StageEnum.FLUENT]: 'Fluent',
  [StageEnum.FONETICA_STAGE_ONE_MODULE_ONE]: 'FONÉTICA Stage One - Module 1',
  [StageEnum.FONETICA_STAGE_ONE_MODULE_TWO]: 'FONÉTICA Stage One - Module 2',
  [StageEnum.FONETICA_STAGE_ONE_MODULE_THREE]: 'FONÉTICA Stage One - Module 3',
  [StageEnum.FONETICA_STAGE_TWO_MODULE_FOUR]: 'FONÉTICA Stage Two - Module 4',
  [StageEnum.FONETICA_STAGE_TWO_MODULE_FIVE]: 'FONÉTICA Stage Two - Module 5',
  [StageEnum.FONETICA_STAGE_THREE_MODULE_SIX]: 'FONÉTICA Stage Three - Module 6',
  [StageEnum.FONETICA_STAGE_THREE_MODULE_SEVEN]: 'FONÉTICA Stage Three - Module 7',
  [StageEnum.PHONICS_STAGE_ONE_MODULE_ONE]: 'Phonics Stage One - Module 1',
  [StageEnum.PHONICS_STAGE_ONE_MODULE_TWO]: 'Phonics Stage One - Module 2',
  [StageEnum.PHONICS_STAGE_ONE_MODULE_THREE]: 'Phonics Stage One - Module 3',
  [StageEnum.PHONICS_STAGE_TWO_MODULE_FOUR]: 'Phonics Stage Two - Module 4',
  [StageEnum.PHONICS_STAGE_TWO_MODULE_FIVE]: 'Phonics Stage Two - Module 5',
  [StageEnum.PHONICS_STAGE_THREE_MODULE_SIX]: 'Phonics Stage Three - Module 6',
  [StageEnum.PHONICS_STAGE_THREE_MODULE_SEVEN]: 'Phonics Stage Three - Module 7',
  [StageEnum.SHARED_READING]: 'Shared Reading',
  [StageEnum.TRANSITIONAL]: 'Transitional'
};
