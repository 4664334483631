import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';

import ShareBookLink from '@okapi/domains/Books/components/ShareBookLink';
import { BookTypeEnum } from '@okapi/graphql/types.generated';

import { RawResource } from '../types';

interface Params {
  onShareBook: (bookId: string) => void;
}

export const getColumns = ({ onShareBook }: Params): ColumnsType<RawResource> => [
  {
    title: 'Title',
    dataIndex: 'title',
    render: (_, { id, title }) => <Link to={`/resources/investigations/${id}`}>{title}</Link>,
    width: 220
  },
  {
    title: 'Series',
    dataIndex: ['series', 'value'],
    width: 100
  },
  {
    title: 'Stage',
    dataIndex: ['stage', 'value'],
    width: 120
  },
  {
    title: 'Actions',
    align: 'right',
    key: 'actions',
    fixed: 'right',
    width: 60,
    render: (_, { id }) => {
      if (!id) return;

      return (
        <ShareBookLink
          bookType={BookTypeEnum.INVESTIGATIONS}
          hasVideoVersion={false}
          onShareButtonClick={() => onShareBook(id)}
        />
      );
    }
  }
];
