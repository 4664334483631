import { Progress } from 'antd';
import React, { FC, ReactNode } from 'react';

import { formatNumber } from '@okapi/utils/formatting';

import { Row } from '../../types';
import styles from './styles.module.less';

interface Props {
  data: Row[];
}

const BarChart: FC<Props> = ({ data }) => {
  if (!data) {
    return null;
  }

  const maxValue: number = Math.max(...data.map(({ value }) => value));

  return data?.map(
    ({ name, value }, index: number): ReactNode => (
      <div key={index} className={styles.row}>
        <div className={styles.desc}>
          <div className={styles.label}>{name}</div>
          <div className={styles.value}>{formatNumber(value)}</div>
        </div>
        <div>
          <Progress
            percent={(value / maxValue) * 100}
            showInfo={false}
            status="active"
            strokeColor={{
              from: '#108ee9',
              to: '#87d068'
            }}
          />
        </div>
      </div>
    )
  );
};

export default BarChart;
